
import Vue from 'vue'
export default Vue.extend({

    data() {
        return {
            valid: true,
            clientName: '',
            clientNameRules: [ (v: string) => !!v || 'Client name is required' ],
            users: [],
            beginRunDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateMenu: false
        }
    },

    computed: {
        usersList() {
            const users = []
            for(const userId of Object.keys(this.$store.getters['users/users'])) {
                if(userId != this.$store.getters['auth/user'].uid) {
                    users.push({
                        email: userId,
                        enterprise: this.$store.getters['users/users'][userId].enterprise
                    })
                }
            }
            return users
        },

        todos() {
            const toDo = []
            if(this.$store.getters['projectForm/securityStepDone'] == false) {
                toDo.push('securityStep')
            }
            if(this.$store.getters['projectForm/deploymentStepDone'] == false) {
                toDo.push('deploymentStep')
            }


            return toDo
        },

    },

    methods: {
        async save() {
            if(this.$refs.form && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
                this.$store.commit('projectForm/setClientName', this.clientName)
                this.$store.commit('projectForm/setUsers', this.users)
                this.$store.commit('projectForm/setBeginRunDate', this.beginRunDate)

                await this.$store.dispatch('projectForm/save')
            }
        }
    },

    mounted() {
        this.clientName = this.$store.getters['projectForm/clientName']
        this.users = this.$store.getters['projectForm/users']
        this.beginRunDate = this.$store.getters['projectForm/beginRunDate'] && this.$store.getters['projectForm/beginRunDate'] != '' ? this.$store.getters['projectForm/beginRunDate'] : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    }
})
